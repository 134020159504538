jQuery(document).ready( function ($) {

    $( '.testimonial-carousel' ).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        adaptiveHeight: false,
    });

});